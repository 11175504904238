import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";
import * as builder from "./hardSkills_builder";

function resetState(state, removeBase = true) {
  state.hardSkills = [];
  state.hardSkillsDisplay = "";
  state.hardSkillLabels = [];
  if (removeBase) {
    state.baseHardSkills = [];
  }
}

const hardSkillsModule = {
  namespaced: true,
  state: {
    hardSkills: [],
    hardSkillsDisplay: "",
    hardSkillLabels: [],
    baseHardSkills: []
  },
  getters: {
    getField,
    level1Array(state) {
      return builder.GetLevelList(state.hardSkillLabels, 1);
    },
    levelLists(state) {
      return [
        builder.GetLevelDisplay(state.hardSkillLabels, 1),
        builder.GetLevelDisplay(state.hardSkillLabels, 2),
        builder.GetLevelDisplay(state.hardSkillLabels, 3),
      ];
    },
    levelLabels(state) {
      return [
        builder.GetLevelLabel(state.hardSkillLabels, 1),
        builder.GetLevelLabel(state.hardSkillLabels, 2),
        builder.GetLevelLabel(state.hardSkillLabels, 3)
      ];
    }
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace("Loading Hard Skills Module", "hardSkills_store.loadData");
      state.hardSkills = payload.HardSkills;
      state.hardSkillsDisplay = payload.HardSkillsDisplay;
      state.hardSkillLabels = payload.HardSkillLabels;
    },
    loadBaseHardSkills(state, payload) {
      log.logTrace(
        "Loading Base Hard Skills",
        "hardSkills_store.loadBaseHardSkills"
      );
      state.baseHardSkills = payload.baseHardSkills;
    },
    removeResume(state) {
      resetState(state, false);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveHardSkills(context) {
      let result = await resApi.PostHardSkills({
        resumeId: context.rootState.resumeId,
        hardSkills: context.state.hardSkills,
        hardSkillLabels: context.state.hardSkillLabels,
        hardSkillsDisplay: context.state.hardSkillsDisplay
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save Hard Skills data",
          "hardSkills_store.saveHardSkills"
        );
      }
    }
  }
};

export { hardSkillsModule };
