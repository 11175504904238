export function dateToMonthYear(date) {
  if (date == null) {
    return null;
  }

  if (!Object.prototype.toString.call(date) === "[object Date]") {
    return null;
  }

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  let month = date.getMonth();
  let year = date.getFullYear();

  return `${monthNames[month]} ${year}`;
}
