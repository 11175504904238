<template>
  <v-app-bar app clipped-left clipped-right color="appBar" height="70px">
    <v-app-bar-nav-icon @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
    <v-toolbar-title class="header-title pl-4 white--text">
      Resumation
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div class="header-location white--text" v-if="$vuetify.breakpoint.mdAndUp">
      {{ pageTitle }}
    </div>
    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
    <v-avatar
      color="secondary"
      size="48"
      v-if="this.$vuetify.breakpoint.smAndUp && hasUser"
    >
      <span class="grey--text text--darken-4 headline">
        {{ initials }}
      </span>
    </v-avatar>
    <span
      class="white--text pl-4"
      v-if="this.$vuetify.breakpoint.smAndUp && hasUser"
    >
      {{ userInfo.username }}
    </span>
    <v-menu bottom left offset-y tile v-if="hasUser">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon class="white--text">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="this.$vuetify.breakpoint.xs">
          <v-list-item-title>{{ userInfo.username }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="(item, i) in menuItems"
          :key="i"
          @click="navTo(item.href)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

// @ is an alias to /src
export default {
  name: "AppBar",
  data: () => ({
    username: "",
    menuItems: [
      {
        title: "Log Out",
        href: "/Logout",
      },
      {
        title: "User Options",
        href: "/Options",
      },
    ],
    pageTitle: "",
  }),
  props: {
    drawer: {
      type: Boolean,
      default: () => [],
    },
  },
  mounted() {},
  computed: {
    ...mapState("auth", ["userInfo"]),
    initials: function () {
      if (this.userInfo.username.trim().length === 0) {
        return "";
      }
      var split = this.userInfo.username.split(" ");
      if (split == null || split.length == 0) {
        return "";
      }
      return split.map((x) => x[0]).reduce((a, c) => a + c, "");
    },
    hasUser: function () {
      if (this.userInfo.username.trim().length === 0) {
        return false;
      }
      return true;
    },
  },
  methods: {
    toggleDrawer() {
      this.$emit("update:drawer", !this.drawer);
    },
    navTo(href) {
      this.$router.push({ path: href });
    },
    handleRoute(routeTo) {
      let title = routeTo.meta.title;
      if (title === undefined || title === null) {
        title = "";
      }
      this.pageTitle = title;
    },
  },
  watch: {
    $route: {
      handler(to) {
        this.handleRoute(to);
      },
      immediate: true,
    },
  },
};
</script>
