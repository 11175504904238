import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";
import * as disp from "../../utility/display";

function resetState(state) {
  state.displayValue = "";
  state.title = "";
  state.firstName = "";
  state.middleName = "";
  state.lastName = "";
  state.suffix = "";
}

const nameModule = {
  namespaced: true,
  state: {
    displayValue: "",
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: ""
  },
  getters: {
    getField,
    fullName(state) {
      let nstr = "";
      if (!disp.IsNullorWhitespace(state.firstName)) {
        nstr += disp.CapitalizeWords(state.firstName);
      }
      if (!disp.IsNullorWhitespace(state.middleName)) {
        nstr += ` ${disp.CapitalizeWords(state.middleName)}`;
      }
      if (!disp.IsNullorWhitespace(state.lastName)) {
        nstr += ` ${disp.CapitalizeWords(state.lastName)}`;
      }
      return nstr;
    },
    shortName(state) {
      let nstr = "";
      if (!disp.IsNullorWhitespace(state.firstName)) {
        nstr += disp.CapitalizeWords(state.firstName);
      }
      if (!disp.IsNullorWhitespace(state.lastName)) {
        nstr += ` ${disp.CapitalizeWords(state.lastName)}`;
      }
      return nstr;
    }
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace("Loading Name Module", "name_store.loadData");
      state.displayValue = payload.displayValue;
      state.title = payload.title;
      state.firstName = payload.firstName;
      state.middleName = payload.middleName;
      state.lastName = payload.lastName;
      state.suffix = payload.suffix;
    },
    removeResume(state) {
      resetState(state);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveName(context) {
      let result = await resApi.PostResName({
        resumeId: context.rootState.resumeId,
        displayValue: context.state.displayValue,
        title: context.state.title,
        firstName: context.state.firstName,
        middleName: context.state.middleName,
        lastName: context.state.lastName,
        suffix: context.state.suffix
      });
      if (!result.success) {
        log.logWarning("Failed to save name data", "name_store.savename");
      }
    }
  }
};

export { nameModule };
