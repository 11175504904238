import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { authModule } from "./modules/auth_store";
import { legalModule } from "./modules/legal_store";
import { nameModule } from "./modules/name_store";
import { addressModule } from "./modules/address_store";
import { adjectiveModule } from "./modules/adjective_store";
import { roleModule } from "./modules/role_store";
import { careerInterestModule } from "./modules/careerInterest_store";
import { personalInterestModule } from "./modules/personalInterest_store";
import { hardSkillsModule } from "./modules/hardSkills_store";
import { licenseCertModule } from "./modules/licenseCert_store";
import { softSkillsModule } from "./modules/softSkills_store";
import { languageModule } from "./modules/language_store";
import { organizationsModule } from "./modules/organizations_store";
import { educationModule } from "./modules/education_store";
import { workModule } from "./modules/work_store";
import { targetIndustryModule } from "./modules/targetIndustry_store";
import { targetCompanyModule } from "./modules/targetCompany_store";
import { strengthsModule } from "./modules/strengths_store";
import { finalInfoModule } from "./modules/finalInfo_store";
import * as log from "../utility/logging/logger";
import * as ResumeAPI from "../api/ResumeApi.js";
import * as SettingsAPI from "../api/SettingsApi.js";
import { getField, updateField } from "vuex-map-fields";
import { settingsModule } from "./modules/settings_store";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      setState: (key, state, storage) => {
        return storage.setItem(key, JSON.stringify(state));
      },
      getState: (key, storage) => {
        const value = storage.getItem(key);
        try {
          if (typeof value !== "undefined") {
            let obj = JSON.parse(value);

            // set dates since they'll parse into strings instead of dates... :/
            // eslint-disable-next-line
            for (const [key, eduObj] of Object.entries(obj?.education?.educationDict)) {
              if (typeof eduObj?.educationGeneral !== "undefined") {
                if (eduObj.educationGeneral.dateStart !== null) {
                  eduObj.educationGeneral.dateStart = new Date(
                    eduObj.educationGeneral.dateStart
                  );
                }
                if (eduObj.educationGeneral.dateEnd !== null) {
                  eduObj.educationGeneral.dateEnd = new Date(
                    eduObj.educationGeneral.dateEnd
                  );
                }
                if (eduObj.educationGeneral.dateExpected !== null) {
                  eduObj.educationGeneral.dateExpected = new Date(
                    eduObj.educationGeneral.dateExpected
                  );
                }
              }
            }

            // set dates since they'll parse into strings instead of dates... :/
            // eslint-disable-next-line
            for (const [key, workObj] of Object.entries(obj?.work?.workDict)) {
              if (typeof workObj?.workGeneral !== "undefined") {
                if (workObj.workGeneral.startDate !== null) {
                  workObj.workGeneral.startDate = new Date(
                    workObj.workGeneral.startDate
                  );
                }
                if (workObj.workGeneral.endDate !== null) {
                  workObj.workGeneral.endDate = new Date(
                    workObj.workGeneral.endDate
                  );
                }
              }
            }

            return obj;
          } else {
            return undefined;
          }
        } catch (err) { } // eslint-disable-line

        return undefined;
      }
    })
  ],
  state: {
    UserInfo: {
      name: "",
      id: ""
    },
    resumeId: "",
    resumeList: []
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    setResumeList(state, data) {
      state.resumeList = data;
    },
    setSettings(state, data) {
      if (data.reportServerUrl != null) {
        this.commit("settings/loadData", {
          reportServerUrl: data.reportServerUrl,
          applicationInsightsKey: data.applicationInsightsKey,
          applicationName: data.applicationName,
        });
      } else {
        log.logWarning("data.reportServerUrl was null", "index.setSettings");
      }
    },
    setBaseLists(state, data) {
      if (data.baseAdjectives != null) {
        this.commit("adjective/loadBaseAdjectives", {
          baseAdjectives: data.baseAdjectives
        });
      } else {
        log.logWarning("data.baseAdjectives was null", "index.SetFullBase");
      }

      if (data.baseCareerInterests != null) {
        this.commit("careerInterest/loadBaseCareerInterests", {
          baseCareerInterests: data.baseCareerInterests
        });
      } else {
        log.logWarning(
          "data.baseCareerInterests was null",
          "index.SetFullBase"
        );
      }

      if (data.baseHardSkills != null) {
        this.commit("hardSkill/loadBaseHardSkills", {
          baseHardSkills: data.baseHardSkills
        });
      } else {
        log.logWarning("data.baseHardSkills was null", "index.SetFullBase");
      }

      if (data.baseLanguages != null) {
        this.commit("language/loadBaseLanguages", {
          baseLanguages: data.baseLanguages
        });
      } else {
        log.logWarning("data.baseLanguages was null", "index.SetFullBase");
      }

      if (data.baseRoles != null) {
        this.commit("role/loadBaseRoles", { baseRoles: data.baseRoles });
      } else {
        log.logWarning("data.baseRoles was null", "index.SetFullBase");
      }

      if (data.baseSoftSkills != null) {
        this.commit("softSkill/loadBaseSoftSkills", {
          baseSoftSkills: data.baseSoftSkills
        });
      } else {
        log.logWarning("data.baseSoftSkills was null", "index.SetFullBase");
      }

      if (data.baseIndustries != null) {
        this.commit("targetIndustry/loadBaseIndustries", {
          baseIndustries: data.baseIndustries
        });
      } else {
        log.logWarning("data.baseIndustries was null", "index.SetFullBase");
      }
    },
    setFullResume(state, data) {
      if (data.ResName != null) {
        this.commit("name/loadData", data.ResName);
      } else {
        log.logWarning("data.ResName was null", "index.setFullResume");
      }

      if (data.Address != null) {
        this.commit("address/loadData", data.Address);
      } else {
        log.logWarning("data.Address was null", "index.setFullResume");
      }

      if (data.SelectedAdjectives != null) {
        this.commit("adjective/loadData", data.SelectedAdjectives);
      } else {
        log.logWarning(
          "data.SelectedAdjectives was null",
          "index.setFullResume"
        );
      }

      if (data.SelectedRoles != null) {
        this.commit("role/loadData", data.SelectedRoles);
      } else {
        log.logWarning("data.SelectedRoles was null", "index.setFullResume");
      }

      if (data.CareerInterest != null) {
        this.commit("careerInterest/loadData", data.CareerInterest);
      } else {
        log.logWarning("data.CareerInterest was null", "index.setFullResume");
      }

      if (data.PersonalInterest != null) {
        this.commit("personalInterest/loadData", data.PersonalInterest);
      } else {
        log.logWarning("data.PersonalInterest was null", "index.setFullResume");
      }

      if (data.HardSkill != null) {
        this.commit("hardSkill/loadData", data.HardSkill);
      } else {
        log.logWarning("data.HardSkill was null", "index.setFullResume");
      }

      if (data.LicensesCert != null) {
        this.commit("licenseCert/loadData", data.LicensesCert);
      } else {
        log.logWarning("data.LicensesCert was null", "index.setFullResume");
      }

      if (data.SoftSkill != null) {
        this.commit("softSkill/loadData", data.SoftSkill);
      } else {
        log.logWarning("data.SoftSkill was null", "index.setFullResume");
      }

      if (data.Language != null) {
        this.commit("language/loadData", data.Language);
      } else {
        log.logWarning("data.Language was null", "index.setFullResume");
      }

      if (data.ProfessionalOrganization != null) {
        this.commit(
          "organization/loadProfessionalData",
          data.ProfessionalOrganization
        );
      } else {
        log.logWarning(
          "data.ProfessionalOrganization was null",
          "index.setFullResume"
        );
      }

      if (data.VolunteerOrganizations != null) {
        this.commit(
          "organization/loadVolunteerData",
          data.VolunteerOrganizations
        );
      } else {
        log.logWarning(
          "data.VolunteerOrganizations was null",
          "index.setFullResume"
        );
      }

      if (data.Education != null) {
        this.commit("education/loadData", data.Education);
      } else {
        log.logWarning("data.Education was null", "index.setFullResume");
      }

      if (data.Work != null) {
        this.commit("work/loadData", data.Work);
      } else {
        log.logWarning("data.Work was null", "index.setFullResume");
      }

      if (data.TargetIndustry != null) {
        this.commit("targetIndustry/loadData", data.TargetIndustry);
      } else {
        log.logWarning("data.TargetIndustry was null", "index.setFullResume");
      }

      if (data.TargetCompany != null) {
        this.commit("targetCompany/loadData", data.TargetCompany);
      } else {
        log.logWarning("data.TargetCompany was null", "index.setFullResume");
      }

      if (data.Strengths != null) {
        this.commit("strengths/loadData", data.Strengths);
      } else {
        log.logWarning("data.Strengths was null", "index.setFullResume");
      }

      if (data.FinalInfo != null) {
        this.commit("finalInfo/loadData", data.FinalInfo);
      } else {
        log.logWarning("data.FinalInfo was null", "index.setFullResume");
      }
    },
    removeResume() {
      Object.entries(this._modules.root._children).forEach(([module, obj]) => {
        if (obj._rawModule.mutations.removeResume !== undefined) {
          this.commit(`${module}/removeResume`);
        }
      });
    },
    logout() {
      Object.entries(this._modules.root._children).forEach(([module, obj]) => {
        if (obj._rawModule.mutations.logout !== undefined) {
          this.commit(`${module}/logout`);
        }
      });
    }
  },
  actions: {
    async loadSettings(context) {
      let result = await SettingsAPI.GetSettings();
      if (!result.success) {
        log.logError(`Could not retrieve settings.`, "index.loadSettings");
      } else {
        log.logTrace(`Retrieved settings.`, "index.loadSettings");
        context.commit("setSettings", result);
        
        if (result.environment == "localdev") {
          log.setLogConsole(true);
        }
      }
    },
    async loadBaseLists(context) {
      let result = await ResumeAPI.GetBaseLists();

      if (!result.success) {
        log.logError(`Could not retrieve base lists.`, "index.loadBaseLists");
      } else {
        log.logTrace(`Retrieved base lists.`, "index.loadBaseLists");
        context.commit("setBaseLists", result);
      }
    },
    async loadFullResume(context, resumeId) {
      let result = await ResumeAPI.GetFullResume(resumeId);

      if (!result.success) {
        log.logError(
          `Could not retrieve resume for ${resumeId}`,
          "index.loadFullResume"
        );
      } else {
        log.logTrace(
          `Retrieved resume for ${resumeId}`,
          "index.loadFullResume"
        );
        context.commit("setFullResume", result.data);
      }
    },
    async loadResumeList(context) {
      let result = await ResumeAPI.GetResumesForUser();

      if (!result.success) {
        log.logError(
          `Could not retrieve resume list for user.`,
          "index.loadResumeList"
        );
      } else {
        log.logTrace(`Retrieved resume list for user.`, "index.loadResumeList");
        context.commit("setResumeList", result.data);
      }
    }
  },
  modules: {
    auth: authModule,
    legal: legalModule,
    name: nameModule,
    address: addressModule,
    adjective: adjectiveModule,
    role: roleModule,
    careerInterest: careerInterestModule,
    personalInterest: personalInterestModule,
    hardSkill: hardSkillsModule,
    licenseCert: licenseCertModule,
    softSkill: softSkillsModule,
    language: languageModule,
    organization: organizationsModule,
    education: educationModule,
    work: workModule,
    targetIndustry: targetIndustryModule,
    targetCompany: targetCompanyModule,
    strengths: strengthsModule,
    finalInfo: finalInfoModule,
    settings: settingsModule,
  }
});
