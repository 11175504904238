<template>
  <v-dialog v-model="value" @click:outside="closeDialog()" :max-width="$vuetify.breakpoint.xs ? 290 : 600">
    <v-card tile>
      <v-system-bar
        :height="$vuetify.breakpoint.xs ? 75 : 50"
        color="primary"
        class="white--text elevation-5"
      >
        <span class="text-h6 pl-2">{{ title }}</span>
      </v-system-bar>
      <v-system-bar height="5" color="accent" class=""></v-system-bar>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ResumationDialog",
  data: () => ({
  }),
  props: {
    value: {
      type: Boolean
    },
    title: {
      type: String
    }
  },
  components: {},
  methods: {
    closeDialog: function() {
      this.$emit("input", false);
    }
  },
  mounted() {},
  computed: {}
};
</script>
