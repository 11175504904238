<template>
  <div class="d-flex flex-column justify-space-between height-100">
    <div>
      <h2 class="mb-2">{{ title }}</h2>
      <p
        v-for="line in asideLines"
        :key="line"
        class="text-body-1 font-italic text--secondary mb-1"
      >
        {{ line }}
      </p>
    </div>
    <page-bottom
      class="mt-7"
      v-if="this.$vuetify.breakpoint.mdAndUp && !noBottom"
      :nextText="nextText"
      :buttonTabIndex="buttonTabIndex"
      eventName="bottomNav"
      @bottomNav="nav()"
    ></page-bottom>
  </div>
</template>

<script>
import PageBottom from "./PageBottom.vue";

// @ is an alias to /src
export default {
  name: "PageLeft",
  data: () => ({}),
  props: {
    title: {
      type: String,
      default: ""
    },
    aside: {
      type: [String, Array],
      default: ""
    },
    nextText: {
      type: String,
      default: ""
    },
    buttonTabIndex: {
      type: String
    },
    eventName: {
      type: String,
      default: "nav"
    },
    noBottom: {
      type: Boolean,
      deafult: false
    }
  },
  components: {
    "page-bottom": PageBottom
  },
  methods: {
    nav() {
      this.$emit(this.eventName);
    }
  },
  mounted() {},
  computed: {
    asideLines() {
      if (this.aside.constructor === String) {
        return [this.aside];
      }
      return this.aside;
    }
  }
};
</script>
