<template>
  <v-navigation-drawer v-model="innerDrawer" app clipped color="navDrawer">
    <v-list dense flat v-for="section in sections" :key="section.title">
      <v-list-item class="text-subtitle-1">{{ section.title }}</v-list-item>
      <v-divider></v-divider>
      <v-list-item-group v-model="activeHeader" active-class="outer-selected">
        <div v-for="link in section.links" :key="link.path">
          <v-list-item @click="nav(link.path)" :value="pathClean(link.path)">
            <v-list-item-content>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list class="pl-4 pt-0" v-if="showSubLinks(link)" flat>
            <v-list-item-group
              v-model="link.subLinkSelected"
              active-class="inner-selected"
            >
              <v-list-item
                v-for="sublink in visibleList(link.subLinks)"
                :key="sublink.path"
                @click="nav(sublink.path)"
                :value="pathClean(sublink.path)"
              >
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ sublink.text }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// @ is an alias to /src
export default {
  name: "NavDrawer",
  data: () => ({
    innerDrawer: false,
    activeHeader: "",
    sections: [
      {
        title: "Resume",
        links: [
          {
            text: "Overview",
            path: "/Resume",
            subLinkSelected: "",
          },
          {
            text: "General",
            path: "/Information",
            subLinkSelected: "",
            subLinks: [
              {
                text: "General Information",
                path: "/Information",
              },
              {
                text: "Address",
                path: "/Address",
              },
              {
                text: "Adjectives",
                path: "/Adjectives",
              },
              {
                text: "Career",
                path: "/Career",
              },
              {
                text: "Hard Skills",
                path: "/HardSkills",
              },
              {
                text: "Soft Skills",
                path: "/SoftSkills",
              },
              {
                text: "Organizations",
                path: "/Organizations",
              },
              {
                text: "Review",
                path: "/ReviewGeneralInfo",
              },
            ],
          },
          {
            text: "Education",
            path: "/Education",
            subLinkSelected: "",
            subLinks: [
              {
                text: "Education Overview",
                path: "/Education",
              },
              {
                text: "General",
                path: "/EducationEntryGeneral",
                hidden: true,
              },
              {
                text: "Tests",
                path: "/EducationEntryDegree",
                hidden: true,
              },
              {
                text: "Awards",
                path: "/EducationEntryAwards",
                hidden: true,
              },
              {
                text: "Award Focus",
                path: "/EducationEntryAwardFocus",
                hidden: true,
              },
            ],
          },
          {
            text: "Work",
            path: "/Work",
            subLinkSelected: "",
            subLinks: [
              {
                text: "Work Overview",
                path: "/Work",
              },
              {
                text: "Company",
                path: "/WorkCompanyGeneral",
                hidden: true,
              },
              {
                text: "Roles",
                path: "/WorkRoles",
                hidden: true,
              },
              {
                text: "Accomplishmets",
                path: "/WorkAccomplishments",
                hidden: true,
              },
            ],
          },
          {
            text: "Final Information",
            path: "/FinalInfo",
            subLinkSelected: "",
          },
          {
            text: "Resume",
            path: "/Review",
            subLinkSelected: "",
          },
        ],
      },
      {
        title: "Cover Letter",
        links: [
          {
            text: "Introduction",
            path: "/CoverLetterIntro",
            subLinkSelected: ""
          },
          {
            text: "Professional Development",
            path: "/TargetIndustry",
            subLinkSelected: "",
            subLinks: [
              {
                text: "Target Industry",
                path: "/TargetIndustry",
              },
              {
                text: "Target Company",
                path: "/TargetCompany",
              },
            ],
          },
          {
            text: "Strengths",
            path: "/Strengths",
            subLinkSelected: "",
            subLinks: [
              {
                text: "Strengths Overview",
                path: "/Strengths",
              },
              {
                text: "Strengths Category",
                path: "/StrengthsCategory",
                hidden: true,
              },
            ],
          },
          {
            text: "Cover Letter",
            path: "/CoverLetterReview",
            subLinkSelected: ""
          },
        ],
      },
    ],
  }),
  props: {
    drawer: {
      type: Boolean,
      default: () => [],
    },
  },
  mounted() {
    this.innerDrawer = this.drawer;
  },
  methods: {
    visibleList(list) {
      return list.filter(
        (l) => l.hidden === undefined || l.hidden === null || l.hidden === false
      );
    },
    showSubLinks(link) {
      if (link.subLinks === undefined) {
        return false;
      }
      return this.pathCompare(this.activeHeader, link.path);
    },
    pathCompare(str1, str2) {
      let cleanS1 = this.pathClean(str1);
      let cleanS2 = this.pathClean(str2);
      if (cleanS1 === "" && cleanS2 === "") {
        return false;
      }
      return cleanS1 === cleanS2;
    },
    pathClean(str) {
      let cleanStr = str.split("/").filter((s) => s.length > 0);
      if (cleanStr.length === 0) {
        return "";
      }
      return cleanStr[0].toLowerCase();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    handleRoute(route) {
      route = this.pathClean(route);

      // set actve header in case we've got it
      this.activeHeader = route;

      // set activeHeaders
      let i = 0;
      let j = 0;
      let k = 0;
      for (k = 0; k < this.sections.length; k++) {
        for (i = 0; i < this.sections[k].links.length; i++) {
          this.sections[k].links[i].subLinkSelected = "";
          for (j = 0; j < this.sections[k].links[i]?.subLinks?.length; j++) {
            if (this.pathCompare(this.sections[k].links[i].subLinks[j].path, route)) {
              this.sections[k].links[i].subLinkSelected = route;
              this.activeHeader = this.pathClean(this.sections[k].links[i].path);
              return;
            }
          }
        }
      }
    },
  },
  watch: {
    drawer: function () {
      this.innerDrawer = this.drawer;
    },
    innerDrawer: function () {
      this.$emit("update:drawer", this.innerDrawer);
    },
    $route: {
      handler(to) {
        this.handleRoute(to.path);
      },
      immediate: true,
    },
  },
  computed: {},
};
</script>

<style scoped>
.inner-selected {
  border-left-width: 10px;
  border-left-style: solid;
  border-left-color: var(--v-navSelected-base);
  margin-left: -4px;
  padding-left: 10px;
}

.outer-selected {
  background-color: var(--v-navSelected-base);
}
</style>
