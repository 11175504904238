import * as authApi from "../../api/AuthApi";
import * as log from "../../utility/logging/logger";

function resetState(state) {
  state.userInfo.username = "";
  state.userInfo.id = "";
  state.auth.authType = "";
  state.isAuthed = false;
  state.refreshTimeout = -1;
}

async function HandleRefresh(context) {
  var response = await authApi.RefreshToken();
  if (!response) {
    context.commit("logout");
  } else {
    let newRefresh = setTimeout(() => HandleRefresh(context), 3600000); //60 * 60 * 1000
    context.commit("setRefreshTimeout", newRefresh);
  }
}

async function LogUserIn(context) {
  let authResponse = await authApi.GetToken();

  let refreshTimeout = -1;

  // check if the call was successful.
  if (authResponse.success) {
    if (!authResponse.data) {
      context.commit("logout");
      log.logError(
        "Did not receive any data in auth response",
        "auth_store.logUserIn"
      );
      return false;
    }
    let data = authResponse.data;

    refreshTimeout = setTimeout(() => HandleRefresh(context), 3600000); //60 * 60 * 1000
    context.commit("login", {
      username: data.username,
      id: data.id,
      authType: data.providerName,
      refreshTimeout: refreshTimeout
    });

    return true;
  } else {
    context.commit("logout");
    return false;
  }
}

const authModule = {
  namespaced: true,
  state: {
    userInfo: {
      username: "",
      id: ""
    },
    auth: {
      authType: ""
    },
    isAuthed: false,
    refreshTimeout: -1,
  },
  mutations: {
    login(state, payload) {
      state.isAuthed = true;
      state.userInfo.username = payload.username;
      state.userInfo.id = payload.id;
      state.auth.authType = payload.authType;
      state.refreshTimeout = payload.refreshTimeout;
    },
    // no removeResume
    logout(state) {
      clearTimeout(state.refreshTimeout);
      resetState(state);
    },
    setRefreshTimeout(state, payload) {
      state.refreshTimeout = payload;
    }
  },
  getters: {},
  actions: {
    async logUserIn(context) {
      return LogUserIn(context);
    }
  }
};

export { authModule };
