import Vue from "vue";
import VueRouter from "vue-router";
import ResumeLanding from "../views/ResumeLanding.vue";
import Login from "../views/login/Login.vue";
import Home from "../views/Home.vue";
import * as env from "./../utility/env";
import store from "../store/index";

Vue.use(VueRouter);

const LOGIN = "Login";
const DEVLOGIN = "Developer Login";
const LOGOUT = "Log Out";
const TERMS = "Terms Of Use";
const PRIVACY = "Privacy Notice";
const COOKIE = "Cookie Notice";
const HOME = "Home";

const TITLEBASE = "Resumation";

const routes = [
  {
    path: "/",
    name: HOME,
    component: Home,
    meta: { hideNav: true, hideBar: true }
  },
  {
    path: "/Resume",
    name: "ResumeLanding",
    component: ResumeLanding,
    meta: { hideNav: true, hideBar: false }
  },
  {
    path: "/login",
    name: LOGIN,
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Login" },
    component: Login
  },
  {
    path: "/dev/Login",
    name: DEVLOGIN,
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Login" },
    component: () => import("../views/login/DevLogin.vue")
  },
  {
    path: "/logout",
    name: LOGOUT,
    meta: { hideNav: true, hideBar: true },
    component: () => import("../views/login/Logout.vue")
  },
  {
    path: "/information",
    name: "Information",
    meta: { title: "General Information" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Information.vue")
  },
  {
    path: "/address",
    name: "Address",
    meta: { title: "Address" },
    component: () => import("../views/Address.vue")
  },
  {
    path: "/education",
    name: "Education",
    meta: { title: "Education Overview" },
    component: () => import("../views/Education.vue")
  },
  {
    path: "/adjectives",
    name: "Adjectives and Roles",
    meta: { title: "Adjectives and Roles" },
    component: () => import("../views/Adjectives.vue")
  },
  {
    path: "/career",
    name: "Career and Personal Interests",
    meta: { title: "Career and Personal Interests" },
    component: () => import("../views/CareerInterest.vue")
  },
  {
    path: "/hardskills",
    name: "Technical and Hard Skills",
    meta: { title: "Technical and Hard Skills" },
    component: () => import("../views/HardSkills.vue")
  },
  {
    path: "/softskills",
    name: "Languages and Soft Skills",
    meta: { title: "Languages and Soft Skills" },
    component: () => import("../views/SoftSkills.vue")
  },
  {
    path: "/organizations",
    name: "Organizations and Volunteering",
    meta: { title: "Organizations and Volunteering" },
    component: () => import("../views/Organizations.vue")
  },
  {
    path: "/educationEntryGeneral/:key",
    name: "Education Entry",
    meta: { title: "Education - General Information" },
    component: () => import("../views/EducationEntryGeneral.vue")
  },
  {
    path: "/educationEntryDegree/:key",
    name: "Education Entry - Degree",
    meta: { title: "Education - Degree" },
    component: () => import("../views/EducationEntryDegree.vue")
  },
  {
    path: "/educationEntryAwards/:key",
    name: "Education Entry - Awards",
    meta: { title: "Education - Awards" },
    component: () => import("../views/EducationEntryAwards.vue")
  },
  {
    path: "/educationEntryAwardFocus/:key",
    name: "Education Entry - Award Focus",
    meta: { title: "Education - Award Focus" },
    component: () => import("../views/EducationEntryAwardFocus.vue")
  },
  {
    path: "/WorkCompanyGeneral/:key",
    name: "General Company Information",
    meta: { title: "Work - General Information" },
    component: () => import("../views/WorkCompanyGeneral.vue")
  },
  {
    path: "/WorkRoles/:key",
    name: "Work Roles",
    meta: { title: "Work - Roles" },
    component: () => import("../views/WorkRoles.vue")
  },
  {
    path: "/WorkAccomplishments/:key",
    name: "Work Accomplishments",
    meta: { title: "Work - Accomplishments" },
    component: () => import("../views/WorkAccomplishments.vue")
  },
  {
    path: "/Work",
    name: "Work",
    meta: { title: "Work Overview" },
    component: () => import("../views/Work.vue")
  },
  {
    path: "/TargetIndustry",
    name: "Target Industry",
    meta: { title: "Target Industry" },
    component: () => import("../views/CoverLetter/TargetIndustry.vue")
  },
  {
    path: "/TargetCompany",
    name: "Target Company",
    meta: { title: "Target Company" },
    component: () => import("../views/CoverLetter/TargetCompany.vue")
  },
  {
    path: "/Strengths",
    name: "Strengths",
    meta: { title: "Strengths Overview" },
    component: () => import("../views/CoverLetter/Strengths.vue")
  },
  {
    path: "/StrengthsCategory/:key",
    name: "Strengths Category",
    meta: { title: "Strengths - Category" },
    component: () => import("../views/CoverLetter/StrengthsCategory.vue")
  },
  {
    path: "/FinalInfo",
    name: "FinalInfo",
    meta: { title: "Final Information" },
    component: () => import("../views/FinalInfo.vue")
  },
  {
    path: "/Review",
    name: "Review",
    meta: { title: "Resume" },
    component: () => import("../views/Review.vue")
  },
  {
    path: "/ReviewOld",
    name: "ReviewOld",
    component: () => import("../views/ReviewOld.vue")
  },
  {
    path: "/ReviewGeneralInfo",
    name: "Review General Information",
    meta: { title: "Review General Information" },
    component: () => import("../views/ReviewGeneralInfo.vue")
  },
  {
    path: "/Tester",
    name: "Tester",
    component: () => import("../views/Tester.vue")
  },
  {
    path: "/TermsOfUse",
    name: TERMS,
    component: () => import("../views/TermsAndConditions.vue"),
    meta: { hideNav: true, title: "Terms Of Use" }
  },
  {
    path: "/PrivacyNotice",
    name: PRIVACY,
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: { hideNav: true, title: "Privacy Notice" }
  },
  {
    path: "/CookieNotice",
    name: COOKIE,
    component: () => import("../views/CookieNotice.vue"),
    meta: { hideNav: true, title: "Cookie Notice" }
  },
  {
    path: "/Options",
    name: "Options",
    component: () => import("../views/Options.vue"),
    meta: { title: "User Options" }
  },
  {
    path: "/CoverLetterIntro",
    name: "Cover Letter Introduction",
    component: () => import("../views/CoverLetter/CoverLetterIntro.vue"),
    meta: { title: "Cover Letter: Introduction" }
  },
  {
    path: "/CoverLetterReview",
    name: "Cover Letter Review",
    component: () => import("../views/CoverLetter/CoverLetterReview.vue"),
    meta: { title: "Cover Letter: Review" }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) { //eslint-disable-line 
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  }
});

// login guard
router.beforeResolve((to, from, next) => {  //eslint-disable-line

  let indicatorCookie = document.cookie.split('; ').find(r => r.startsWith("rs_ind")) !== undefined;
  
  if (to.name === HOME) {
    next({ name: LOGIN }); // removing home page
  } else if (env.IsDevelopmentEnv() && to.name === DEVLOGIN) {
    next();
  } else if (to.name === LOGIN || to.name === LOGOUT) {
    next();
  } else if (to.name === TERMS || to.name === PRIVACY || to.name === COOKIE) {
    next();
  } else if (store.state.auth.isAuthed && indicatorCookie) {
    next();
  } else {
    next({ name: LOGIN });
  }
});

// change page title
// https://stackoverflow.com/questions/51639850/how-to-change-page-titles-when-using-vue-router
router.afterEach((to, from) => {    //eslint-disable-line 
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    if (to.meta.title) {
      document.title = `${TITLEBASE} - ${to.meta.title}`
    }
    else {
      document.title = TITLEBASE;
    }
  });
});

export default router;
