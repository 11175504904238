import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import "./styles/style.scss";
import "./styles/opensans.scss";
import axios from "axios";
import VueAppInsights from "vue-application-insights";
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { unload } from "./utility/unloadHandler";
import VueKonva from "vue-konva";
import * as log from "./utility/logging/logger.js";

import PageMain from "./components/page/PageMain.vue";
import ResumationDialog from "./components/ResumationDialog.vue";
import ResumationDualList from "./components/ResumationDualList.vue";
import ResumationMultiSelect from "./components/ResumationMultiSelect.vue";
import ResumationMultiSelectLevels from "./components/ResumationMultiSelectLevels.vue";
import ResumationSingleSelect from "./components/ResumationSingleSelect.vue";
import ResumationSwitch from "./components/ResumationSwitch.vue";
import ResumationTextArea from "./components/ResumationTextArea.vue";
import ResumationTextField from "./components/ResumationTextField.vue";
import MonthDateSelect from "./components/MonthDateSelect.vue";
import ResumationExpansionPanels from "./components/expansionpanel/ResumationExpansionPanels.vue";
import ResumationExpansionPanel from "./components/expansionpanel/ResumationExpansionPanel.vue";
import ResumationExpansionPanelContent from "./components/expansionpanel/ResumationExpansionPanelContent.vue";
import ResumationExpansionPanelHeader from "./components/expansionpanel/ResumationExpansionPanelHeader.vue";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

(async () => {

  // Set up application insights
  let curSettings;
  try {
    await store.dispatch("loadSettings");
    curSettings = store.getters['settings/getSettings'];
    curSettings.success = true;
  }
  catch (exception) {
    console.log(exception);
    console.log("In catch");
    curSettings.applicationInsightsKey = "e5435217-daa2-4f2a-b80a-46078923129f";   // Dev env
    curSettings.applicationName = "Resumation - Error During Settings Init!";
    curSettings.success = false;
    curSettings.ex = exception;
  }

  let appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: curSettings.applicationInsightsKey
    }
  });
  
  appInsights.loadAppInsights();
  appInsights.trackPageView();

  log.setAppInsights(appInsights);

  Vue.use(VueAppInsights, {
    appInsights: appInsights,
    trackInitialPageView: false,
    router: router,
    baseName: curSettings.applicationName
  });

  if (!curSettings.success) {
    appInsights.trackTrace({
      message: "Exception setting up application insights!",
      severity: 4,
      properties: { exception: curSettings.exception }
    });
  }

  // set up Konva for drawing resume
  Vue.use(VueKonva);

  // Register r-* components
  Vue.component("page-main", PageMain);
  Vue.component("r-dialog", ResumationDialog);
  Vue.component("r-dual-list", ResumationDualList);
  Vue.component("r-multi-select", ResumationMultiSelect);
  Vue.component("r-multi-select-levels", ResumationMultiSelectLevels);
  Vue.component("r-single-select", ResumationSingleSelect);
  Vue.component("r-switch", ResumationSwitch);
  Vue.component("r-textarea", ResumationTextArea);
  Vue.component("r-text-field", ResumationTextField);
  Vue.component("month-date-select", MonthDateSelect);
  Vue.component("r-expansion-panels", ResumationExpansionPanels);
  Vue.component("r-expansion-panel", ResumationExpansionPanel);
  Vue.component("r-expansion-panel-content", ResumationExpansionPanelContent);
  Vue.component("r-expansion-panel-header", ResumationExpansionPanelHeader);

  new Vue({
    created() {
      window.addEventListener("beforeunload", this.unloadHandler);
    },
    methods: {
      unloadHandler: function handler() {
        unload();
      }
    },
    router,
    vuetify,
    store,
    render: h => h(App)
  }).$mount("#app");
})();